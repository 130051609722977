body {
    padding-top: 120px;
    background-image: url('../../public/marca_dagua_1.png');
    background-color: #E8F5FF;
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 40vw;
    background-attachment: fixed;
}

pre {
    background: #f7f7f9;
}

/* Estilos para o componente BoardMembers */

/* Contêiner principal */
.main-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Linha de cards */
.cards-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

/* Ajuste para a segunda linha de cards */
.cards-row:nth-child(2) {
    gap: 10px;
}

/* Card específico para o board */
.card-board {
    max-width: 1800px;
    margin: 10px;
}

/* Card específico para as comissões */
.card-parties {
    margin: 10px;
    flex: 2;
}

/* Contêiner da legenda */
.legend-container {
    text-align: left;
    margin-top: 10px;
    max-width: 300px;
    max-height: 100px;
}

/* Estilos para a tabela responsiva */
.responsive-table {
    flex: 1;
    overflow-x: auto;
}

.responsive-table table {
    width: 100%;
    border-collapse: collapse;
}

.responsive-table th,
.responsive-table td {
    text-align: left;
    padding: 5px;
    border: 1px solid #ddd;
}

.responsive-table th {
    background-color: #f8f8f8;
}

.responsive-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.board-role-cell {
    font-weight: bold;
    background-color: #fff8dc;
    vertical-align: 'middle';
}

/* Estilos para as linhas e células dos cargos da diretoria */
.board-role-row {
    background-color: #fff8dc;
}

.board-role-cell td {
    font-weight: bold;
    background-color: #fff8dc;
    vertical-align: 'middle';
}

.payment-done td {
    background-color: #CEFFBA;
    vertical-align: 'middle';
}

.payment-expired td {
    background-color: #FFD1D1;
    vertical-align: 'middle';
}

.member-border {
    border-top: 3px solid #000 !important;
    /* Exemplo de borda mais grossa */
}


/* Estilos para o header do ano */
.year-header {
    font-size: 2.7em;
    text-align: center;
    margin-bottom: 40px;
}

.year-taxes {
    font-size: 2.7em;
    text-align: left;
    margin-bottom: 40px;
}

/* Botão pequeno */
.small-button {
    width: 30px;
    height: 30px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
}

.small-button .pi {
    font-size: 0.8em;
}

/* Botão de reset */
.reset-button {
    margin: 20px;
    align-self: center;
}

/* Caixa de cor para a legenda */
.legend-color-box {
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    background-color: #fff8dc;
    margin-right: 10px;
}

/* Media query para dispositivos móveis */
@media (max-width: 768px) {
    .cards-row {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
    }

    .cards-row>* {
        flex: 1;
    }

    .legend-container {
        margin-top: 0;
        text-align: center;
    }
}

.login-wrapper .col-md-12,
.login-wrapper .col-lg-10 {
    width: 100%;
}

/* Outros estilos existentes */
#home,
#help {
    font-size: 0.9rem;
}

#home .navbar,
#help .navbar {
    background: #349aed;
    background: linear-gradient(145deg, #349aed 50%, #34d8ed 100%);
    transition: box-shadow 200ms ease-in;
}

#home .navbar-transparent,
#help .navbar-transparent {
    background: none !important;
    box-shadow: none;
}

#home .navbar-brand .nav-link,
#help .navbar-brand .nav-link {
    display: inline-block;
    margin-right: -30px;
}

#home .navbar-brand img,
#help .navbar-brand img {
    display: inline-block;
    margin: 0 10px;
    width: 30px;
}

#home .nav-link,
#help .nav-link {
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
}

#home {
    padding-top: 0px;
}

#home .btn {
    padding: 0.6rem 0.55rem 0.5rem;
    box-shadow: none;
    font-size: 0.7rem;
    font-weight: 500;
}

.bs-docs-section {
    margin-top: 4em;
}

.bs-docs-section .page-header h1 {
    padding: 2rem 0;
    font-size: 3rem;
}

.dropdown-menu.show[aria-labelledby="themes"] {
    display: flex;
    width: 420px;
    flex-wrap: wrap;
}

.dropdown-menu.show[aria-labelledby="themes"] .dropdown-item {
    width: 33.333%;
}

.dropdown-menu.show[aria-labelledby="themes"] .dropdown-item:first-child {
    width: 100%;
}

.bs-component {
    position: relative;
}

.bs-component+.bs-component {
    margin-top: 1rem;
}

.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bs-component .card {
    margin-bottom: 1rem;
}

.bs-component .modal {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    z-index: 1;
    display: block;
}

.bs-component .modal-dialog {
    width: 90%;
}

.bs-component .popover {
    position: relative;
    display: inline-block;
    width: 220px;
    margin: 20px;
}

.source-button {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    font-weight: bold;
}

.source-button:hover {
    cursor: pointer;
}

.bs-component:hover .source-button {
    display: block;
}

.custom-nav-dropdown .dropdown-item:hover {
    background-color: #b3e0ff !important;
}

#source-modal pre {
    max-height: calc(100vh - 11rem);
    background-color: rgba(0, 0, 0, 0.7);
    color: rgba(255, 255, 255, 0.7);
}

.nav-tabs {
    margin-bottom: 15px;
}

.progress {
    margin-bottom: 10px;
}

#footer {
    margin: 5em 0;
}

#footer li {
    float: left;
    margin-right: 1.5em;
    margin-bottom: 1.5em;
}

#footer p {
    clear: left;
    margin-bottom: 0;
}

.splash {
    padding: 12em 0 6em;
    background: #349aed;
    background: linear-gradient(145deg, #349aed 50%, #34d8ed 100%);
    color: #fff;
    text-align: center;
}

.splash .logo {
    width: 160px;
}

.splash h1 {
    font-size: 3em;
    color: #fff;
}

.splash #social {
    margin: 2em 0 3em;
}

.splash .alert {
    margin: 2em 0;
    border: none;
}

.splash .sponsor a {
    color: #fff;
}

.section-tout {
    padding: 6em 0 1em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    background-color: #eaf1f1;
}

.section-tout .fa {
    margin-right: 0.2em;
}

.section-tout p {
    margin-bottom: 5em;
}

.section-preview {
    padding: 4em 0 4em;
}

.section-preview .preview {
    margin-bottom: 4em;
    background-color: #eaf1f1;
}

.section-preview .preview img {
    max-width: 100%;
}

.section-preview .preview .image {
    position: relative;
}

.section-preview .preview .image:before {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    pointer-events: none;
}

.section-preview .preview .options {
    padding: 2em;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-top: none;
    text-align: center;
}

.section-preview .preview .options p {
    margin-bottom: 2em;
}

.section-preview .dropdown-menu {
    text-align: left;
}

.section-preview .lead {
    margin-bottom: 2em;
}

@media (max-width: 767px) {
    .section-preview .image img {
        width: 100%;
    }
}

.sponsor img {
    max-width: 100%;
}

.sponsor #carbonads {
    max-width: 240px;
    margin: 0 auto;
}

.sponsor .carbon-text {
    display: block;
    margin-top: 1em;
    font-size: 12px;
}

.sponsor .carbon-poweredby {
    float: right;
    margin-top: 1em;
    font-size: 10px;
}

@media (max-width: 767px) {
    .splash {
        padding-top: 8em;
    }

    .splash .logo {
        width: 100px;
    }

    .splash h1 {
        font-size: 2em;
    }

    #banner {
        margin-bottom: 2em;
        text-align: center;
    }
}

.active-link {
    color: white;
}

.rbc-today {
    border: 4px solid gold;
    border-radius: 0px;
}

.custom-nav-dropdown {
    background-color: #b3e0ff !important;
}

.custom-nav-dropdown .dropdown-menu {
    background-color: #b3e0ff !important;
}

.custom-nav-dropdown .dropdown-item {}

.custom-nav-dropdown .dropdown-item.active {
    color: white;
}

.nav-separator {
    margin-left: auto;
}

.no-margin {
    margin-left: 0;
    margin-top: 0;
    padding-left: 0;
    padding-top: 0;
}

.button-container {
    display: flex;
    align-items: center;
}

.auto-width {
    white-space: nowrap;
    width: 1%;
    text-align: left;
}

.table th,
.table td {
    text-align: left;
    white-space: nowrap;
    padding: 2px 5px;
}

.table th.auto-width,
.table td.auto-width {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
}

body,
html {
    height: 100%;
    margin: 0;
}

.centralized-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
}

.centralized-header {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.centralized-content {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 800px;
}

.centralized-content .card {
    width: 100%;
}

@media (max-width: 768px) {
    .centralized-content {
        flex-direction: column;
        align-items: center;
    }

    .centralized-content .card {
        margin-bottom: 20px;
    }

    .attendance-guest-container {
        flex-direction: column;
        align-items: center;
    }

    .attendance-guest-container .form-row {
        width: 100%;
    }

    .attendance-guest-container .card {
        margin-bottom: 20px;
    }

    .responsive-table {
        display: block;
        width: 100%;
        overflow-x: auto;
    }

    .responsive-table table {
        width: 100%;
        border-collapse: collapse;
    }

    .responsive-table th,
    .responsive-table td {
        display: block;
        width: 100%;
        box-sizing: border-box;
    }

    .responsive-table th {
        background-color: #f8f8f8;
    }

    .responsive-table td {
        border-top: 1px solid #ddd;
    }

    .responsive-table td:before {
        content: attr(data-label);
        font-weight: bold;
        display: inline-block;
        width: 100px;
    }

    /* Estilos para as células dos cargos da diretoria */
    .board-role-row td {
        font-weight: bold;
        background-color: #e2b70a !important;
    }

    .table td:first-child {
        vertical-align: middle;
    }

    .table-custom {
        width: 100%;
        border-collapse: collapse;
        margin: 50px 0;
        font-size: 18px;
        text-align: left;
    }

    .table-custom thead tr {
        background-color: #009879;
        color: #ffffff;
        text-align: left;
        font-weight: bold;
    }

    .table-custom th,
    .table-custom td {
        padding: 12px 15px;
        border: 1px solid #dddddd;
    }

    .table-custom tbody tr {
        border-bottom: 1px solid #dddddd;
    }

    .table-custom tbody tr:nth-of-type(even) {
        background-color: #f3f3f3;
    }

    .table-custom tbody tr:last-of-type {
        border-bottom: 2px solid #009879;
    }
}

/* event-register.css */

/* Aumenta a largura do pop-up do react-datepicker */
.custom-calendar .react-datepicker__month-container {
    width: 300px;
    /* Ajuste conforme necessário */
}

/* Garante que o pop-up fique acima de outros elementos */
.custom-calendar {
    z-index: 1050;
    /* Deve ser maior que outros elementos na página */
}

/* Opcional: Ajusta a posição do triângulo do calendário */
.custom-calendar .react-datepicker__triangle {
    left: 50%;
    margin-left: -8px;
    /* Ajusta para centralizar */
}